import React, { useState, useRef, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { getPresignedUrl, uploadAudioFile } from "../services/file.service";
import { PageLayout } from "../components/page-layout";

const CONFIG = {
  DEFAULT_CHUNK_DURATION: 15
};

// SVG Icons Components
const IconMic = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M12 2a3 3 0 0 0-3 3v7a3 3 0 0 0 6 0V5a3 3 0 0 0-3-3Z"/>
    <path d="M19 10v2a7 7 0 0 1-14 0v-2"/>
    <line x1="12" x2="12" y1="19" y2="22"/>
  </svg>
);

const IconSquare = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <rect x="3" y="3" width="18" height="18" rx="2" ry="2"/>
  </svg>
);

const IconPlay = () => (
  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <polygon points="5 3 19 12 5 21 5 3"/>
  </svg>
);

const IconDownload = () => (
  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"/>
    <polyline points="7 10 12 15 17 10"/>
    <line x1="12" y1="15" x2="12" y2="3"/>
  </svg>
);

export const RecordPage = () => {
  const { getAccessTokenSilently } = useAuth0();
  
  // State
  const [isRecording, setIsRecording] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [chunkDuration, setChunkDuration] = useState(CONFIG.DEFAULT_CHUNK_DURATION);
  const [uploadStatus, setUploadStatus] = useState('');
  const [debugInfo, setDebugInfo] = useState('');

  // Refs
  const mediaRecorderRef = useRef(null);
  const audioStreamRef = useRef(null);
  const chunkCounterRef = useRef(0);

  const addDebugInfo = (info) => {
    const timestamp = new Date().toLocaleTimeString();
    const logMessage = `${timestamp}: ${info}`;
    console.log(logMessage);
    setDebugInfo(prev => `${logMessage}\n${prev}`);
  };

  const processChunk = async (chunkNumber, blob) => {
    try {
      setUploadStatus(`Uploading chunk ${chunkNumber}...`);
      const accessToken = await getAccessTokenSilently();
      const presignedUrlResponse = await getPresignedUrl(accessToken);
      
      if (presignedUrlResponse?.url) {
        await uploadAudioFile(presignedUrlResponse.url, blob);
        setUploadStatus(`Chunk ${chunkNumber} uploaded`);
        addDebugInfo(`Chunk ${chunkNumber} uploaded successfully`);
      }
    } catch (error) {
      addDebugInfo(`Failed to upload chunk ${chunkNumber}: ${error.message}`);
      setUploadStatus(`Failed to upload chunk ${chunkNumber}`);
    }
  };

  const startNewChunk = () => {
    if (!audioStreamRef.current || !isRecording) {
      addDebugInfo('No audio stream available or not recording');
      return;
    }

    const currentChunkNumber = chunkCounterRef.current + 1;
    chunkCounterRef.current = currentChunkNumber;
    addDebugInfo(`Starting chunk ${currentChunkNumber}`);

    const chunks = [];

    try {
      mediaRecorderRef.current = new MediaRecorder(audioStreamRef.current);

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunks.push(event.data);
          addDebugInfo(`Data received for chunk ${currentChunkNumber}: ${event.data.size} bytes`);
        }
      };

      mediaRecorderRef.current.onstart = () => {
        addDebugInfo(`Recording started for chunk ${currentChunkNumber}`);
      };

      mediaRecorderRef.current.onstop = async () => {
        addDebugInfo(`Recording stopped for chunk ${currentChunkNumber}`);

        if (chunks.length > 0) {
          const blob = new Blob(chunks, { type: 'audio/webm' });
          addDebugInfo(`Created blob for chunk ${currentChunkNumber}: ${blob.size} bytes`);

          // Add to UI immediately
          setRecordedChunks(prev => [{
            number: currentChunkNumber,
            blob,
            timestamp: new Date().toLocaleTimeString(),
            duration: chunkDuration
          }, ...prev]);

          // Process upload separately
          processChunk(currentChunkNumber, blob);
        }

        // If still recording, start next chunk immediately
        if (isRecording) {
          addDebugInfo(`Initiating next chunk after ${currentChunkNumber}`);
          // Small delay to ensure clean transition
          setTimeout(startNewChunk, 100);
        }
      };

      // Request data every second
      mediaRecorderRef.current.start(1000);

      // Set timeout to stop this chunk
      setTimeout(() => {
        if (mediaRecorderRef.current?.state === 'recording') {
          addDebugInfo(`Stopping chunk ${currentChunkNumber} after ${chunkDuration}s`);
          mediaRecorderRef.current.stop();
        }
      }, chunkDuration * 1000);

    } catch (error) {
      addDebugInfo(`Error in chunk ${currentChunkNumber}: ${error.message}`);
      if (isRecording) {
        setTimeout(startNewChunk, 1000);
      }
    }
  };
  const startRecording = async () => {
    try {
      addDebugInfo('Starting recording session');
      
      const stream = await navigator.mediaDevices.getUserMedia({ 
        audio: {
          channelCount: 1,
          echoCancellation: true,
          noiseSuppression: true
        } 
      });
      
      audioStreamRef.current = stream;
      addDebugInfo('Got audio stream');
      
      // Reset counter
      chunkCounterRef.current = 0;
      
      // Set recording state
      setIsRecording(true);
      
      // Start first chunk
      startNewChunk();
      
    } catch (error) {
      addDebugInfo(`Error starting recording: ${error.message}`);
      alert('Error accessing microphone. Please ensure you have granted permissions.');
    }
  };

  const stopRecording = () => {
    addDebugInfo('Stopping recording session');
    setIsRecording(false);
    
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop();
    }

    if (audioStreamRef.current) {
      audioStreamRef.current.getTracks().forEach(track => track.stop());
      audioStreamRef.current = null;
    }
  };

  const playChunk = (blob) => {
    const url = URL.createObjectURL(blob);
    const audio = new Audio(url);
    audio.onended = () => URL.revokeObjectURL(url);
    audio.play();
    addDebugInfo('Playing chunk');
  };

  const downloadChunk = (blob, number) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `chunk-${number}.webm`;
    a.click();
    URL.revokeObjectURL(url);
    addDebugInfo(`Downloading chunk ${number}`);
  };

  useEffect(() => {
    return () => {
      if (isRecording) {
        stopRecording();
      }
    };
  }, [isRecording]);

  return (
    <PageLayout>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">Recording Studio</h1>
        <div className="content__body">
          <div className="w-full max-w-2xl mx-auto bg-white rounded-xl shadow-lg p-6">
            <div className="flex justify-between items-center mb-6">
              <div>
                <h2 className="text-xl font-semibold text-gray-900">Audio Recorder</h2>
                {uploadStatus && (
                  <p className="text-sm text-gray-600 mt-1">{uploadStatus}</p>
                )}
              </div>
              <button
                onClick={isRecording ? stopRecording : startRecording}
                className={`p-3 rounded-full ${
                  isRecording 
                    ? 'bg-red-500 hover:bg-red-600' 
                    : 'bg-blue-500 hover:bg-blue-600'
                } text-white transition-colors`}
              >
                {isRecording ? <IconSquare /> : <IconMic />}
              </button>
            </div>

            <div className="bg-gray-50 rounded-lg p-4 mb-6">
              <h2 className="text-lg font-medium text-gray-700 mb-4">Controls</h2>
              <div className="space-y-4">
                <div className="flex items-center gap-4">
                  <label className="min-w-[140px] text-gray-600">
                    Chunk Duration:
                  </label>
                  <input
                    type="range"
                    min="5"
                    max="60"
                    step="5"
                    value={chunkDuration}
                    onChange={(e) => setChunkDuration(Number(e.target.value))}
                    className="flex-1"
                  />
                  <span className="text-sm text-gray-600 min-w-[60px] text-right">
                    {chunkDuration}s
                  </span>
                </div>
              </div>
            </div>

            {/* Debug Info Display */}
            <div className="mt-4 p-4 bg-gray-100 rounded">
              <h3 className="text-sm font-semibold mb-2">Debug Info:</h3>
              <pre className="text-xs whitespace-pre-wrap h-40 overflow-auto">
                {debugInfo}
              </pre>
            </div>

            <div className="bg-gray-50 rounded-lg p-4 mt-6">
              <h2 className="text-lg font-medium text-gray-700 mb-4">Recorded Chunks</h2>
              <div className="space-y-3">
                {recordedChunks.map((chunk, index) => (
                  <div key={index} className="flex items-center justify-between bg-white p-3 rounded-lg shadow-sm">
                    <div className="flex items-center gap-3">
                      <span className="bg-blue-100 text-blue-800 px-2 py-1 rounded text-sm">
                        Chunk {chunk.number}
                      </span>
                      <span className="text-gray-600 text-sm">
                        {chunk.timestamp} ({chunk.duration}s)
                      </span>
                    </div>
                    <div className="flex gap-2">
                      <button
                        onClick={() => playChunk(chunk.blob)}
                        className="flex items-center gap-1 px-3 py-1 bg-green-500 text-white rounded hover:bg-green-600"
                      >
                        <IconPlay />
                        Play
                      </button>
                      <button
                        onClick={() => downloadChunk(chunk.blob, chunk.number)}
                        className="flex items-center gap-1 px-3 py-1 bg-gray-100 text-gray-700 rounded hover:bg-gray-200"
                      >
                        <IconDownload />
                        Download
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};
